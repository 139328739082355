import React from 'react';
import { Helmet } from 'react-helmet';
import { Layout, Typography, Space, Row, Col, Card } from 'antd';

const { Content, Footer } = Layout;
const { Text } = Typography;

const IndexPage: React.FC = () => {
  return <Content>
    <Helmet>
      <title>하이소사이어티</title>
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />

      <link type="text/css" rel="stylesheet" href={"/css/layout.css"} />
      <link rel="apple-touch-icon" sizes="57x57" href="/icon/apple-icon-57x57.png" />
      <link rel="apple-touch-icon" sizes="60x60" href="/icon/apple-icon-60x60.png" />
      <link rel="apple-touch-icon" sizes="72x72" href="/icon/apple-icon-72x72.png" />
      <link rel="apple-touch-icon" sizes="76x76" href="/icon/apple-icon-76x76.png" />
      <link rel="apple-touch-icon" sizes="114x114" href="/icon/apple-icon-114x114.png" />
      <link rel="apple-touch-icon" sizes="120x120" href="/icon/apple-icon-120x120.png" />
      <link rel="apple-touch-icon" sizes="144x144" href="/icon/apple-icon-144x144.png" />
      <link rel="apple-touch-icon" sizes="152x152" href="/icon/apple-icon-152x152.png" />
      <link rel="apple-touch-icon" sizes="180x180" href="/icon/apple-icon-180x180.png" />
      <link rel="icon" type="image/png" sizes="192x192" href="/icon/android-icon-192x192.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/icon/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="96x96" href="/icon/favicon-96x96.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/icon/favicon-16x16.png" />
      <link rel="manifest" href="/icon/manifest.json" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="msapplication-TileImage" content="/icon/ms-icon-144x144.png" />
      <meta name="theme-color" content="#ffffff" />

      <meta name="author" content="하이소사이어티" />
      <meta name="description" content="상위 1%들의 만남, 성공을 위해 열심히 달려온 당신, 이제는 행복해질 시간이 되었습니다." />

      <meta property="og:title" content="하이소사이어티" />
      <meta property="og:description" content="상위 1%들의 만남, 성공을 위해 열심히 달려온 당신, 이제는 행복해질 시간이 되었습니다." />
      <meta property="og:image" content="https://highso.app/images/meta-intro.png" />

      <script async src="https://www.googletagmanager.com/gtag/js?id=UA-127746049-3"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
        
          gtag('config', 'UA-127746049-3');
        `}
      </script>
    </Helmet>
    <div>
      <div style={{
        backgroundImage: 'url("/images/highso_bg.png")',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        padding: '8rem 0',
        textAlign: 'center',
        color: '#fff'
      }}>
        <div>
          <h1 style={{ color: '#fff' }}> HIGH SOCIETY </h1>
          <h2 style={{ color: '#fff' }}> 상위 1%들의 만남, 하이소사이어티</h2>
          <br />
          <div className="hide-sm">
            <p><h3 style={{ color: '#fff' }}>성공을 위해 열심히 달려온 당신, 이제는 행복해질 시간이 되었습니다.</h3></p>
            <p><h3 style={{ color: '#fff' }}>하이소사이어티는 성공을 이룬 남성회원과 자신만의 매력을 가꾼 여성회원을</h3></p>
            <p><h3 style={{ color: '#fff' }}>매칭해 주는 프리미엄 데이팅 서비스로 기존 서비스들과는 다른 차별점을 가지고 있습니다.</h3></p>
          </div>
          <div className="show-sm">
            <h3 style={{ color: '#fff' }}>
              성공을 위해 열심히 달려온 당신,<br />이제는 행복해질 시간이 되었습니다.<br /><br />
						  하이소사이어티는 성공을 이룬 남성회원과<br />자신만의 매력을 가꾼 여성회원을<br />
						  매칭해 주는 프리미엄 데이팅 서비스로<br />기존 서비스들과는 다른 차별점을 가지고 있습니다.
            </h3>
          </div>
          <div style={{ marginTop: 50 }} />
          <Row justify="center" align="middle" >
            <Space size="large" >
              <a href="https://highso.app/download?p=ios"><img src={'/images/appstore.svg'} /></a>
              <a href="https://highso.app/download?p=android"><img src={'/images/playstore.svg'} /></a>
            </Space>
          </Row>
        </div>
      </div>
      <div style={{ maxWidth: 600, margin: '0 auto', textAlign: 'center', marginTop: 100 }}>
        <img src={'/images/icon.png'} style={{ borderRadius: 12, height: 60, width: 60, marginBottom: 10 }} />
        <h3 style={{ color: '#ffffff' }}>‘하이소사이어티’ 란?</h3>
      </div>

      <div style={{ margin: '0 20px' }}>
        <br />
        <Card style={{ maxWidth: 600, margin: '0 auto', padding: '10px 20px' }}>
          <div>
            <div style={{ color: '#5755d9', fontWeight: 'bold' }}>하나,</div>
            <div style={{ fontWeight: 'bold' }}>남성회원은 본인의 능력을 검증해야만 가입됩니다.</div>
          </div>
          <div style={{ marginTop: 10 }}>
            외모, 전문직, 사업가, 자산가, 고위공무원 등 남성의 경우 본인의 경제력 혹은 능력을 검증하는 서류를 제출해야 합니다. 이후 하이소사이어티 심사팀의 까다로운 인증심사 후 가입이 완료됩니다.
				  </div>
        </Card>
        <br />
        <Card style={{ maxWidth: 600, margin: '0 auto', padding: '10px 20px' }}>
          <div>
            <div style={{ color: '#ffb700', fontWeight: 'bold' }}>둘, </div>
            <div style={{ fontWeight: 'bold' }} >여성회원은 프로필 검증을 통해 가입됩니다.</div>
          </div>
          <div style={{ marginTop: 10 }}>
            아나운서, 모델, 승무원, 전문직 등 본인의 매력을 어필할 수 있는 프로필을 업로드 해주시면 평가를 통해 가입이 완료됩니다.
				  </div>
        </Card>
        <br />
        <Card style={{ maxWidth: 600, margin: '0 auto', padding: '10px 20px' }}>
          <div>
            <div style={{ color: '#e85600', fontWeight: 'bold' }}>셋, </div>
            <div style={{ fontWeight: 'bold' }}>커뮤니티, 이벤트 게시판 등 다양한 활동이 가능합니다.</div>
          </div>
          <div style={{ marginTop: 10 }}>
            인증심사를 완료한 회원은 매일 새로운 이성회원들을 소개받음과 동시에 커뮤니티, 이벤트 게시판 등을 통해 회원들간의 자유로운 교류와 만남이 가능합니다.
				  </div>
        </Card>
        <div style={{ maxWidth: 600, margin: '0 auto', textAlign: 'center', marginTop: 50 }}>
          <h3 style={{ color: '#ffffff' }}>" 당신의 운명의 상대를 찾아보세요 "</h3>
        </div>
        <div style={{ marginTop: 50 }} />
        <Row justify="center" align="middle" >
          <Space size="large">
            <a href="https://highso.app/download?p=ios"><img src={'/images/appstore.svg'} /></a>
            <a href="https://highso.app/download?p=android"><img src={'/images/playstore.svg'} /></a>
          </Space>
        </Row>
      </div>
      <footer style={{ textAlign: 'right', marginTop: 100 }}>
        <div id="copyright" style={{ color: '#ffffff', padding: '0 20px' }}>
          <p>Licensed under the <a href="#" target="_blank">Nuca, Inc</a>.</p>
        </div>
      </footer>
    </div>
  </Content >
}

export default IndexPage;